import axios from "axios";
import Ekmekk from "../Ekmekk/Helper/ekmekk";

export default function Shipment() {
    function List() {

        return new Promise((success, fail) => {
            if (Ekmekk().Profile.GetData().type == 1) {
                var data = new FormData();
                data.append('driverId', Ekmekk().Profile.GetData().id);

                axios.post('http://www.skylojistik.com/sosTest/api/sevkiyat_api.php?sevkiyat=basSofor', data)
                    .then((response) => {
                        success(response.data.data.shippings)
                      
                    }).catch((error) => {
                        console.log(error)
                    })


            }
            else {
                console.log("şoför");

                var data = new FormData();
                data.append('driverId', Ekmekk().Profile.GetData().id);

                axios.post('http://www.skylojistik.com/sosTest/api/sevkiyat_api.php?sevkiyat=sofor', data)
                    .then((response) => {
                        success(response.data.data.shippings)
                    }).catch((error) => {
                        console.log(error)
                    })


            }
        })


    }
    return {
        List
    }
}