import axios from "axios";

export default function Fuel() {
    function Give(name, date, liter, distance, price, licensePlate, driverName, shippingId) {

        return new Promise((success, fail) => {
            var data = new FormData();
            data.append('name', name);
            data.append('date', date);
            data.append('liter', liter);
            data.append('distance', distance);
            data.append('price', price);
            data.append('licensePlate', licensePlate);
            data.append('driverName', driverName);
            data.append('shippingId', shippingId);

            axios.post('http://www.skylojistik.com/sosTest/api/yakit_api.php?yakit=ekle', data)
                .then((response) => {
                    success(response.data)
                }).catch((error) => {
                    console.log(error)
                })
        })


    }
    return {
        Give
    }
}