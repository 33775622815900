import React, { useEffect, useState, useRef } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Clear';
import axios from 'axios'

var render = 0


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        marginTop: 6,

    },
    pos: {
        marginBottom: 12,
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
        padding: theme.spacing(1, 2, 1),

    },
    heroContent: {
        padding: theme.spacing(4, 2, 1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    py2: {
        paddingTop: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    center: {
        paddingTop: 275,
        alignItems: 'center',
    },
    submit: {
        marginTop: '20px'
    },
    imageList: {
        width: '100%',
        height: '100%',
    },
    icon: {
        position: 'absolute',
        top: '3px',
        right: '3px',
        color: 'white',
    },
    container: {
        padding: '3rem 3rem',
        textAlign: 'center'
    },
    container2: {
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        height: '80px',
        backgroundColor: 'white',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center'

    },


}));

export default function UploadImage({ addImageButton }) {
    const classes = useStyles();
    const theme = useTheme();
    const inputFile = useRef(null)

    const { loadID, loadJobID, type } = useParams();
    const [imageList, setImageList] = useState([]);

    const handleChange = e => {
        if (e.target.files.length) {
            var array = [...imageList]
            for (var i = 0; i < e.target.files.length; i++) {
                var imageObj = {
                    preview: URL.createObjectURL(e.target.files[i]),
                    raw: e.target.files[i]
                }
                array.push(imageObj)
            }
            setImageList(array);
        }
    };

    const deletePhoto = (index) => {
        var array = [...imageList]
        array.splice(index, 1)
        setImageList(array)
    }

    useEffect(() => {
        if (render > 0) {
            inputFile.current.click()
        }
        render++
    }, [addImageButton])

    const postImages = () => {
        var typeValue = 0
        type == "loadPhoto" ? typeValue = 1 : type == "onwayPhoto" ? typeValue = 2 : typeValue = 3
       let data = new FormData()
       imageList.forEach(img => {
        data.append(`file[${typeValue}]`,img.raw)
       })
       data.append('yuk_id',loadID)
       data.append('yukisemri_no',loadJobID)

       var config = {
        method: 'post',
        url: 'https://www.skylojistik.com/sos/admin/item/resim/upload.php',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };

      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
       
       
    }

    return <>

        <Container className={classes.container} maxWidth="sm" component="main" >
            <input onChange={handleChange} multiple type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
            {imageList.length > 0 ? (
                <>
                    <ImageList rowHeight={180} className={classes.imageList}>
                        {imageList && imageList.map((item, index) => {
                            return <ImageListItem >
                                <img src={item.preview} alt={"Resim"} />
                                <IconButton onClick={() => deletePhoto(index)} aria-label={`info about ${item.preview}`} className={classes.icon}>
                                    <InfoIcon />
                                </IconButton>

                            </ImageListItem>
                        })}

                    </ImageList>

                </>
            ) : (
                <>
                    <Typography variant="h5" align="center" color="textSecondary" component="p" className={classes.center}>
                        Resim yüklemek için sağ üstteki + butonuna tıklayınız
                    </Typography>
                </>
            )}


        </Container>
        <Container className={classes.container2} maxWidth="sm" component="main" >
            <Button
                onClick={() => postImages()}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                align="center"
            >
                Resimleri Gönder
            </Button>
        </Container>


    </>
}