import React, { useEffect, useState, useRef } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';

import Ekmekk from '../../Ekmekk/Helper/ekmekk'
import axios from "../../axios";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        // display: 'flex',
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        marginTop: 6,

    },
    pos: {
        marginBottom: 12,
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
        padding: theme.spacing(1, 2, 1),

    },
    heroContent: {
        padding: theme.spacing(4, 2, 1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    py2: {
        paddingTop: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    py4: {
        // paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
}));


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function LoadList() {
    const classes = useStyles();

    const { shipID } = useParams();

    const [open, setOpen] = React.useState(false);
    var history = useHistory();

    const [loadList, setLoadList] = useState([])
    const [selectedLoad, setSelectedLoad] = useState([])

    const handleClickOpen = (item) => {
        setSelectedLoad(item);
        setOpen(true);
    };
    const handleClose = () => {
        // Ekmekk().Alert.Success();
        setOpen(false);
    };

    useEffect(() => {

        console.log(shipID)
        var data = new FormData();
        data.append('shippingId', shipID);

        axios.post('http://www.skylojistik.com/sosTest/api/yuk_api.php', data)
            .then((response) => {
                console.log(response.data.data.shippings)
                setLoadList(response.data.data.shippings)
            }).catch((error) => {
                console.log(error)
            })


    }, [])


    return <>

        <Container maxWidth="sm" component="main" className={classes.heroContent}>

            <Typography variant="h7" align="center" color="textSecondary" component="p">
                Aktif sevkiyatlarınız aşağıda listelenmektedir.
                İşlem yapmak istediğiniz sevkiyatı seçiniz.
            </Typography>

        </Container>
        {history.location.pathname.includes("self") == false ? loadList.map(item => <Container maxWidth="sm" component="main" className={classes.content} onClick={() => { history.push("/dashboard/load-status/" + item.item_id + "/" + item.item_yukisemri_no) }}>

            {/* <Card className={classes.root} onClick={handleClickOpen}> */}
            <Card className={classes.root}  >
                <CardContent >
                    {/* <NavLink to={"/dashboard/load-status/1"} className="menu-link menu-toggle"> */}
                    <Typography component="h5" variant="h5"  >
                        {item.item_name}
                    </Typography>
                    {/* </NavLink> */}
                </CardContent>
            </Card>

        </Container>) : loadList.map(item => <Container maxWidth="sm" component="main" className={classes.content} onClick={() => { handleClickOpen(item) }}>

            {/* <Card className={classes.root} onClick={handleClickOpen}> */}
            <Card className={classes.root} onClick={() => { handleClickOpen(item) }} >
                <CardContent >
                    {/* <NavLink to={"/dashboard/load-status/1"} className="menu-link menu-toggle"> */}
                    <Typography component="h5" variant="h5" onClick={() => { handleClickOpen(item) }} >
                        {item.item_name}
                    </Typography>
                    {/* </NavLink> */}
                </CardContent>
            </Card>

        </Container>)}




        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <Container maxWidth="sm" component="main" className={classes.content}>

                <DialogTitle id="customized-dialog-title" >
                    {selectedLoad.item_name} yükü için
                </DialogTitle>
                <DialogContent >
                    {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText> */}
                    <Typography gutterBottom onClick={() => { history.push("/dashboard/load-status/" + selectedLoad.item_id + "/" + selectedLoad.item_yukisemri_no) }}>
                        Fotoğraf Ekle
                    </Typography>
                    <Typography gutterBottom className={classes.py4} onClick={() => { history.push("/dashboard/product-purchase") }} >
                        Ürün Alımı
                    </Typography>
                    <Typography gutterBottom className={classes.py4} onClick={() => { history.push("/dashboard/product-list") }}>
                        Ürünleri Listele
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        İPTAL
                    </Button>
                </DialogActions>
            </Container>
        </Dialog>

        {/* <Container maxWidth="lg" className={classes.container}>
             <Grid container spacing={3}>
                 <Grid item xs={12} md={8} lg={9}>
                     <Paper className={fixedHeightPaper}>
                         <Chart />
                    </Paper>
                 </Grid>
                 <Grid item xs={12} md={4} lg={3}>
                     <Paper className={fixedHeightPaper}>
                       <Deposits />
                     </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                         <Orders />
                    </Paper>
                 </Grid>
             </Grid>

         </Container> */}
    </>
}