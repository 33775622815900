import React from 'react';
import { useParams, useHistory } from 'react-router-dom'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import axios from '../../axios'
import Ekmekk from '../../Ekmekk/Helper/ekmekk';

import { useForm, Controller } from 'react-hook-form'
var qs = require('qs');
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        // display: 'flex',
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        marginTop: 6,

    },
    pos: {
        marginBottom: 12,
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
        
        padding: theme.spacing(1, 2, 6),

    },
    heroContent: {
        padding: theme.spacing(4, 2, 1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    py2: {
        paddingTop: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    textField: {
        marginTop: '20px'
    },
    submit: {
        marginTop : '20px'
    }
}));

export default function ExternalLoad() {
    let {shipID} = useParams()
    let history = useHistory()
    const classes = useStyles();
    const theme = useTheme();

    const { register, handleSubmit, errors } = useForm()

    const externalHandler = (data) => {
        data.isemri_no = shipID
        data = qs.stringify(data)
        

        axios.post('disYuk_api.php',data)
        .then(r => {
            console.log(r.data);
            Ekmekk().Alert.Success()
            history.push("/dashboard/shipment-list")
        })
        .catch(err => {
            console.log(err);
            Ekmekk().Alert.Fail()
        })
    }



    return <>

        <Container maxWidth="sm" component="main" className={classes.heroContent}>

            <Typography variant="h7" align="center" color="textSecondary" component="p">
                Dış yük girişi yapmak için aşağıdaki bilgileri doldurunuz.
            </Typography>

        </Container>

        <form noValidate onSubmit={handleSubmit((externalHandler))}>
        <Container maxWidth="sm" component="main" className={classes.content}>
        <Grid item xs={12} md={6}  >
          <TextField
            className={classes.textField}
            variant="outlined"
            required
            id="nerden"
            label="Nereden"
            helperText="Yükün nereden alındığı"
            fullWidth
            name="nereden"
            inputRef={register({
                required: "Bu Alan boş bıraklamaz!"
            })}
            error={Boolean(errors.nereden)}
            helperText={errors.nereden?.message}
          />
          
          <TextField
            className={classes.textField}
            variant="outlined"
            required
            id="nereye"
            label="Nereye"
            helperText="Yükün nereye götürüleceği"
            fullWidth
            name="nereye"
            inputRef={register({
                required: "Bu Alan boş bıraklamaz!"
            })}
            error={Boolean(errors.nereye)}
            helperText={errors.nereye?.message}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            required
            id="tutar"
            label="İş Bedeli"
            helperText="İşin toplam bedeli"
            fullWidth
            name="tutar"
            inputRef={register({
                required: "Bu Alan boş bıraklamaz!"
            })}
            error={Boolean(errors.tutar)}
            helperText={errors.tutar?.message}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            required
            id="komisyon"
            label="Komisyon"
            helperText="İş için komisyon ücreti"
            fullWidth
            name="komisyon"
            inputRef={register({
                required: "Bu Alan boş bıraklamaz!"
            })}
            error={Boolean(errors.komisyon)}
            helperText={errors.komisyon?.message}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            required
            id="temiz_kalan"
            label="Temiz Kalan"
            helperText="Net kalan ücret"
            fullWidth
            autoComplete="cc-csc"
            name="temiz_kalan"
            inputRef={register({
                required: "Bu Alan boş bıraklamaz!"
            })}
            error={Boolean(errors.temiz_kalan)}
            helperText={errors.temiz_kalan?.message}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            required
            id="nakliyeci_adi"
            label="Nakliyeci Adı"
            helperText="Nakliyeci firmanın adı"
            fullWidth
            name="nakliyeci_adi"
            inputRef={register({
                required: "Bu Alan boş bıraklamaz!"
            })}
            error={Boolean(errors.nakliyeci_adi)}
            helperText={errors.nakliyeci_adi?.message}
          />
          <TextField
            className={classes.textField}
            variant="outlined"
            required
            id="nakliyeci_telefon"
            label="Nakliyeci Firmanın Telefon Numarası"
            helperText="Yükün alındığı kişinin telefon numarası"
            fullWidth
            name="nakliyeci_telefon"
            inputRef={register({
                required: "Bu Alan boş bıraklamaz!"
            })}
            error={Boolean(errors.nakliyeci_telefon)}
            helperText={errors.nakliyeci_telefon?.message}
          />

    <FormControl component="fieldset">
      <RadioGroup  className={classes.textField} row aria-label="position" name="fatura" defaultValue="top">

        <FormControlLabel value="end" control={
            <Radio 
                color="primary"
                name="dsadsa"
                inputRef={register}
                value="Faturalı"
                 />} label="Faturalı" />
        <FormControlLabel value="end" control={
            <Radio 
                color="primary"
                name="dsadsa"
                inputRef={register}
                value="Faturasız"
                 />} label="Faturasız" />

      </RadioGroup>
    </FormControl>  
          <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          >
          Kaydet
         </Button>

        </Grid>
        </Container>
        </form>

    </>
}