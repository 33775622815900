import React, { useEffect, useState, useRef } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faSignOutAlt, faChevronDown, faChevronRight, faTruckLoading, faTruckMoving, faBoxOpen } from "@fortawesome/free-solid-svg-icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        backgroundColor: '#0080ff'
        // display: 'flex',
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        marginTop: 6,

    },
    pos: {
        marginBottom: 12,
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
        padding: theme.spacing(1, 2, 1),
        // backgroundColor: '#cfe8fc'

    },
    heroContent: {
        padding: theme.spacing(4, 2, 1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    py2: {
        paddingTop: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
}));

export default function LoadStatus() {
    const classes = useStyles();
    
    const { loadID, loadJobID } = useParams();
    var history = useHistory();

    useEffect(() => {

        console.log(loadID)
        console.log(loadJobID)
        

    }, [])


    return <>

        <Container maxWidth="sm" component="main" className={classes.heroContent}>

            <Typography variant="h7" align="center" color="textSecondary" component="p">
                Yükün hangi aşamada olduğunu seçiniz.
            </Typography>

        </Container>

        <Container maxWidth="sm" component="main" className={classes.content}>

            <Card className={classes.root} onClick={() => {history.push("/dashboard/load-image/loadPhoto/" + loadID + "/" + loadJobID)}}>
                <CardContent >
                    <Typography component="h5" variant="h5">
                        <FontAwesomeIcon icon={faTruckLoading} size="lg" style={{ color: '#ffffff', paddingRight: "10px" }} />
                        <Typography variant="h7" align="left" style={{ color: '#ffffff' }}>
                            Yüklemede
                        </Typography>
                    </Typography>
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>

        </Container>

        <Container maxWidth="sm" component="main" className={classes.content}>

            <Card className={classes.root} onClick={() => {history.push("/dashboard/load-image/onwayPhoto/" + loadID + "/" + loadJobID)}}>
                <CardContent >
                    <Typography component="h5" variant="h5">
                        <FontAwesomeIcon icon={faTruckMoving} size="lg" style={{ color: '#ffffff', paddingRight: "10px" }} />
                        <Typography variant="h7" align="left" style={{ color: '#ffffff' }}>
                            Yolda
                        </Typography>
                    </Typography>
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>

        </Container>
        
        <Container maxWidth="sm" component="main" className={classes.content}>

            <Card className={classes.root} onClick={() => {history.push("/dashboard/load-image/deliveredPhoto/" + loadID + "/" + loadJobID)}}>
                <CardContent >
                    <Typography component="h5" variant="h5">
                        <FontAwesomeIcon icon={faBoxOpen} size="lg" style={{ color: '#ffffff', paddingRight: "10px" }} />
                        <Typography variant="h7" align="left" style={{ color: '#ffffff' }}>
                            Teslim Edildi
                        </Typography>
                    </Typography>
                </CardContent>
                {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
            </Card>

        </Container>


        {/* <Container maxWidth="lg" className={classes.container}>
             <Grid container spacing={3}>
                 <Grid item xs={12} md={8} lg={9}>
                     <Paper className={fixedHeightPaper}>
                         <Chart />
                    </Paper>
                 </Grid>
                 <Grid item xs={12} md={4} lg={3}>
                     <Paper className={fixedHeightPaper}>
                       <Deposits />
                     </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                         <Orders />
                    </Paper>
                 </Grid>
             </Grid>

         </Container> */}
    </>
}