import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import CardMedia from '@material-ui/core/CardMedia';

import IconButton from '@material-ui/core/IconButton';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        padding: theme.spacing(0, 2, 0, 2),

    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        marginTop: 6,

    },
    pos: {
        marginBottom: 12,
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        // overflow: 'auto',
        padding: theme.spacing(1, 2, 1),

    },
    heroContent: {
        textAlign: 'center',
        padding: theme.spacing(1, 2, 1),
    },
    inputContent: {
        marginBottom: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    fixedHeight: {
        height: 240,
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    py2: {
        paddingTop: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
}));

export default function ProductList() {
    const classes = useStyles();
    const theme = useTheme();

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const bull = <span className={classes.bullet}>•</span>;

    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return <>

        <Container maxWidth="sm" component="main" className={classes.heroContent} justifyContent="center">

            <Button className={classes.inputContent} variant="contained" color="primary">MAİL İLE PAYLAŞ</Button>

        </Container>


        {/* <div className={classes.root}>

            <Grid container spacing={1} justifyContent="center">

                <Button className={classes.inputContent} variant="contained" color="primary">KAYDET</Button>

            </Grid>
        </div> */}

        {/* <Container maxWidth="lg" className={classes.container}>
             <Grid container spacing={3}>
                 <Grid item xs={12} md={8} lg={9}>
                     <Paper className={fixedHeightPaper}>
                         <Chart />
                    </Paper>
                 </Grid>
                 <Grid item xs={12} md={4} lg={3}>
                     <Paper className={fixedHeightPaper}>
                       <Deposits />
                     </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                         <Orders />
                    </Paper>
                 </Grid>
             </Grid>

         </Container> */}
    </>
}