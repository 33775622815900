import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useForm, Controller } from 'react-hook-form'

import axiosDefault from 'axios'
import axios from '../../axios'
import { useHistory, useParams } from 'react-router-dom';
import DataCenter from '../../DataCenter/DataCenter';
import AlertEkmekk from '../../Ekmekk/Views/alert';
var qs = require('qs');


const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        padding: theme.spacing(0, 2, 0, 2),

    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        marginTop: 6,

    },
    pos: {
        marginBottom: 12,
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        // overflow: 'auto',
        padding: theme.spacing(1, 2, 1),

    },
    heroContent: {
        padding: theme.spacing(1, 2, 1),
    },
    inputContent: {
        marginBottom: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    fixedHeight: {
        height: 240,
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    py2: {
        paddingTop: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
}));

export default function DieselInput() {
    const classes = useStyles();
    var history = useHistory();

    const { register, handleSubmit, errors } = useForm()

    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

   function getCurrentDate(separator = '-'){

        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        let hour = newDate.getHours();
        let minute = newDate.getMinutes();
        let seecond = newDate.getSeconds();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date} ${hour}:${minute}:${seecond}`
    }
    const dizelHandler = (data) => {
        DataCenter().Fuel.Give(selectedShip.sevkiyat_ad, getCurrentDate(), data.postmanAdi, data.postmanAdiAracKm,data.yakitFiyat, selectedShip.arac_name, selectedShip.account_name, selectedShip.id).then(r=>{
            AlertEkmekk().Success();
            history.push("/dashboard/shipment-list");

        })
    }
    const { shipID } = useParams();
    const [selectedShip, setSelectedShip] = useState();
    useState(() => {
        const config = {
            "referer": "https://www.opet.com.tr/istanbul-akaryakit-fiyatlari",
            "origin": "https://www.opet.com.tr",
            "DNT": "1",
            "X-Requested-With": "XMLHttpRequest"

        }
        let data = {
            Cityname: "İSTANBUL"
        }
        data = qs.stringify(data)

        axiosDefault.post('https://www.opet.com.tr/AjaxProcess/GetFuelPricesList', data, config)
            .then(r => {
                console.log(r.data)
            })
            .catch(err => {
                console.log(err);
            })


        DataCenter().Shipment.List().then((r) => {
            r.map(it => {
                if (it.id == shipID) {
                    setSelectedShip(it);
                }
            })
        })
    }, [])






    return <>

        <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <Typography variant="h7" align="left">
                <Typography component="h5" variant="h5">
                    İŞ EMRİ NO : {selectedShip?.id}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Sevkiyat Adı: {selectedShip?.sevkiyat_ad}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Yük Sayısı: {selectedShip?.item_count}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Plaka Numarası: {selectedShip?.arac_name}
                </Typography>
            </Typography>


        </Container>


        <div className={classes.root}>

            <form noValidate onSubmit={handleSubmit((dizelHandler))}>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={6}>
                        <TextField
                            id="outlined-full-width"
                            label="Litre"
                            // style={{ margin: 8 }}
                            autoComplete="current-password"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => {
                                alert(e.target.value)
                            }}
                            name="postmanAdi"
                            inputRef={register({
                                required: "Dizel boş bıraklamaz!"
                            })}
                            error={Boolean(errors.postmanAdi)}
                            helperText={errors.postmanAdi?.message}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="outlined-full-width"
                            label="Yakıt Fiyatı"
                            placeholder="7.27"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            name="yakitFiyat"
                            inputRef={register}
                            error={Boolean(errors.yakitFiyat)}
                            helperText={errors.yakitFiyat?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            id="outlined-full-width"
                            label="Tutar"
                            // style={{ margin: 8 }}
                            placeholder="0.00"
                            value="0.00"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            name="tutar"
                            inputRef={register}
                            error={Boolean(errors.tutar)}
                            helperText={errors.tutar?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-full-width"
                            label="Araç KM"
                            placeholder="0.00"
                            style={{ textAnchor: "right" }}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            name="postmanAdiAracKm"
                            inputRef={register({
                                required: "AracKm boş bıraklamaz!"
                            })}
                            error={Boolean(errors.postmanAdiAracKm)}
                            helperText={errors.postmanAdiAracKm?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup row aria-label="position" name="position" defaultValue="top">

                            <FormControlLabel
                                value="a"
                                control={
                                    <Radio
                                        name="dsadsa"
                                        inputRef={register}
                                        value="a"
                                        color="primary"
                                    />}
                                label="Çıkış Mazotu" />
                            <FormControlLabel
                                value="b"
                                control={
                                    <Radio
                                        color="primary"
                                        name="dsadsa"
                                        inputRef={register}
                                        value="b"
                                    />}
                                label="Dönüş Mazotu"
                            />

                        </RadioGroup>
                    </Grid>


                    <Button type="submit" className={classes.inputContent} variant="contained" color="primary" >KAYDET</Button>
                </Grid>
            </form>
        </div>

        {/* <Container maxWidth="lg" className={classes.container}>
             <Grid container spacing={3}>
                 <Grid item xs={12} md={8} lg={9}>
                     <Paper className={fixedHeightPaper}>
                         <Chart />
                    </Paper>
                 </Grid>
                 <Grid item xs={12} md={4} lg={3}>
                     <Paper className={fixedHeightPaper}>
                       <Deposits />
                     </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                         <Orders />
                    </Paper>
                 </Grid>
             </Grid>

         </Container> */}
    </>
}