export default function Profile() {
    function SetProfile(name, value) {
        localStorage.setItem(name, value);
    }

    function GetProfile() {
        return localStorage.getItem("tokenData");
    }

    function SignOut() {
        localStorage.setItem("userType", null);

    }

    function SetData(response) {
        // alert(response["data"]["ek_admin_authority_ID"]);
        localStorage.setItem("userType", JSON.stringify(response));
    }

    function GetData() {
        // alert(response["data"]["ek_admin_authority_ID"]);
        return JSON.parse(localStorage.getItem("userType"));
    }

    function SetPermission(response) {
        // alert(response["data"]["ek_admin_authority_ID"]);
        localStorage.setItem("permission", response);
    }

    function GetUserType() {
        return localStorage.getItem("userType");
    }
    return {
        SetProfile,
        GetProfile,
        SignOut,
        SetData,
        SetPermission,
        GetData
    }
}