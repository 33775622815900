import React, { useEffect, useState } from "react";
import { NavLink, useHistory,useParams } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import Link from '@material-ui/core/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import Ekmekk from "../Ekmekk/Helper/ekmekk";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        // paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        // marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function Dashboard({setAddImageButton, addImageButton}) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    let {path} = useParams()

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);


    const [router, setRouter] = useState(null)

    const [headers, setHeaders] = useState([])
    var history = useHistory();
    useEffect(() => {
        // console.log(Ekmekk().GetHeaders())
        setHeaders(Ekmekk().GetHeaders())
        // console.log(path);
        // console.log(Ekmekk().GetHeaders());
    }, [path])

    function SignOut() {

        localStorage.clear();
        history.push("/login");
        

    }

    function HeadersItemList(item) {

        // RESİM YÜKLEMEYSE BAŞLIĞIN BAŞINA YAZIYI DEĞİŞTİRİYO
        if (item.isPhotoUpload) {
            return <>
                {
                    item.isPhotoUpload == true ? (history.location.pathname.includes("loadPhoto") ? "Yüklemede " + item.itemName : history.location.pathname.includes("onwayPhoto") ? "Yolda " + item.itemName : history.location.pathname.includes("deliveredPhoto") ? "Teslim Edildi " + item.itemName : "") : ""
                }
            </>

        }
        else {
            return <>

                {
                    history.location.pathname.includes(item.router)  ? (item.first ? Ekmekk().Profile.GetData().name + " " + item.itemName : item.itemName) : ""
                }

            </>
        }

    }

    return <>

        <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    // onClick={handleDrawerOpen}
                    className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                >
                    {
                        history.location.pathname == "/dashboard/shipment-list" ? "" : <ArrowBackIcon onClick={() => history.goBack()} />
                    }
                </IconButton>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    {
                        headers.map(item => {
                            return HeadersItemList(item);
                        })
                    }
                </Typography>
                <IconButton color="inherit">
                    {
                        history.location.pathname == "/dashboard/shipment-list" ? <PowerSettingsNewIcon onClick={() => SignOut()}  /> : ""
                    }
                    {
                        history.location.pathname.includes("load-image/") ? <AddIcon onClick={() => setAddImageButton(!addImageButton)} /> : ""
                    }
                    
                </IconButton>



            </Toolbar>
        </AppBar>

        {/* <Drawer
            variant="persistent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.hide),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>{mainListItems}</List>
            <Divider />
            <List>{secondaryListItems}</List>
        </Drawer> */}


    </>
}