import React, { useEffect, useState, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';


import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Ekmekk from '../../Ekmekk/Helper/ekmekk'

import axios from "../../axios";
import DataCenter from "../../DataCenter/DataCenter";
var qs = require('qs');

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        // display: 'flex',
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        marginTop: 6,

    },
    pos: {
        marginBottom: 12,
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
        padding: theme.spacing(1, 2, 1),

    },
    heroContent: {
        padding: theme.spacing(4, 2, 1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    py2: {
        paddingTop: theme.spacing(1),
    },
    py4: {
        // paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    pl2: {
        paddingLeft: theme.spacing(2),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
}));


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


export default function ShipmentList() {
    const classes = useStyles();
    const theme = useTheme();

    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const bull = <span className={classes.bullet}>•</span>;

    var history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [shipmentList, setShipmentList] = useState([])
    const [selectedShipment, setSelectedShipment] = useState([])
    // const selectedShipment = useRef(-1);

    const handleClickOpen = (item) => {
        setSelectedShipment(item)
        setOpen(true);
    };
    const handleClose = () => {
        // Ekmekk().Alert.Success();
        setOpen(false);
    };

    useEffect(() => {
        console.log(Ekmekk().Profile.GetData());


        DataCenter().Shipment.List().then(r => {
            setShipmentList(r)
        })


    }, [])

    return <>

        <Container maxWidth="sm" component="main" className={classes.heroContent}>

            <Typography variant="h7" align="center" color="textSecondary" component="p">
                Aktif sevkiyatlarınız aşağıda listelenmektedir.
                İşlem yapmak istediğiniz sevkiyatı seçiniz.
            </Typography>

        </Container>

        {
            shipmentList.map(item => <Container maxWidth="sm" component="main" className={classes.content}>

                <Card className={classes.root} onClick={() => { handleClickOpen(item) }}>
                    <CardContent >
                        <Typography component="h5" variant="h5">
                            İŞ EMRİ NO : {item.isemri_no}
                        </Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Sevkiyat Adı: {item.sevkiyat_ad}
                        </Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Yük Sayısı: {item.item_count}
                        </Typography>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Plaka Numarası: {item.arac_name}
                        </Typography>
                    </CardContent>
                    {/* <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions> */}
                </Card>

            </Container>)
        }

        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <Container maxWidth="sm" component="main" className={classes.content}>

                <DialogTitle id="customized-dialog-title" >
                    {selectedShipment.isemri_no} iş emri numaralı sevkiyat için
                </DialogTitle>
                <DialogContent >
                    {/* <DialogContentText>
                    To subscribe to this website, please enter your email address here. We will send updates
                    occasionally.
                </DialogContentText> */}
                    <Typography gutterBottom onClick={() => { selectedShipment.account_id != Ekmekk().Profile.GetData().id ? history.push("/dashboard/load-list/" + selectedShipment.id) : history.push("/dashboard/load-list/self/" + selectedShipment.id) }}>
                        Yükleri Listele
                    </Typography>
                    <Typography gutterBottom className={classes.py4} onClick={() => { history.push("/dashboard/load-external/" + selectedShipment.isemri_no) }}>
                        Dış Yük Girişi Yap
                    </Typography>
                    <Typography gutterBottom className={classes.py4} onClick={() => { history.push("/dashboard/shipment-note/" + selectedShipment.id) }}>
                        Not Ekle
                    </Typography>
                    <Typography gutterBottom className={classes.py4} onClick={() => { setOpen2(true);handleClose()}}>
                        Not Görüntüle
                    </Typography>

                    <Typography gutterBottom className={classes.py4} onClick={() => { history.push("/dashboard/diesel-input/" + selectedShipment.id) }}>
                        {
                            Ekmekk().Profile.GetData()?.type == 1 ? "Mazot Al" : "Mazot Ver"
                        }
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        İPTAL
                    </Button>
                </DialogActions>
            </Container>
        </Dialog> 
        <Dialog onClose={()=>{setOpen2(false)}} aria-labelledby="customized-dialog-title" open={open2}>
            <Container maxWidth="sm" component="main" className={classes.content}>

                <DialogTitle id="customized-dialog-title" >
                    {selectedShipment.isemri_no} iş emri numaralı sevkiyat için
                </DialogTitle>
                <DialogContent >
                    {
                        selectedShipment.soforNot
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>{setOpen2(false)}} color="primary">
                        Vazgeç
                    </Button>
                </DialogActions>
            </Container>
        </Dialog>

        {/* <Container maxWidth="lg" className={classes.container}>
             <Grid container spacing={3}>
                 <Grid item xs={12} md={8} lg={9}>
                     <Paper className={fixedHeightPaper}>
                         <Chart />
                    </Paper>
                 </Grid>
                 <Grid item xs={12} md={4} lg={3}>
                     <Paper className={fixedHeightPaper}>
                       <Deposits />
                     </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                         <Orders />
                    </Paper>
                 </Grid>
             </Grid>

         </Container> */}
    </>
}