import Fuel from "./Fuel";
import Shipment from "./Shipment";

export default function DataCenter()
{

    return {
        Shipment : Shipment(),
        Fuel : Fuel(),
    }
}