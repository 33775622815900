import axios from 'axios';

let headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    //headers.Authorization = Ekmekk().Profile.GetProfile()

const instance = axios.create({
    baseURL: 'http://www.skylojistik.com/sos/api/',

});


export default instance;