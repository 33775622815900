import React, { useEffect, useState } from "react"
import Ekmekk from './Ekmekk/Helper/ekmekk';
import Main from './Thema/main';
import Login from './Pages/Login/login'
import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect
} from "react-router-dom";

function App() {
  


  function Body() {


     if (localStorage.getItem("userType") == null) {
       return <Redirect to='/login' />
     } else {
       if (localStorage.getItem("isAdmin") == "1")
         return <Redirect to='/dashboard/shipment-list' />
       else
         return <Redirect to='/dashboard/shipment-list' />

     }
  }



  return <Router >
      {
        Body()
      }

    <Switch >
   
   
      <Route path="/dashboard" >
        <Main />
      </Route>
      <Route path="/login" >
        <Login />
      </Route>
    
    </Switch>

  </Router>

}

export default App;
