
import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

import SevkiyatList from "../Pages/Sevkiyat/ShipmentList"
import LoadList from "../Pages/Yük/LoadList";
import LoadStatus from "../Pages/Yük/LoadStatus";
import UploadImage from "../Pages/ResimYükle/UploadImage";
import ExternalLoad from "../Pages/DışYük/ExternalLoad";
import ShipmentNote from "../Pages/Sevkiyat/ShipmentNote";
import DieselInput from "../Pages/Mazot/DieselInput";
import Raport from "../Pages/Rapor/Raport";
import ProductList from "../Pages/Ürün/ProductList";
import ProductPurchase from "../Pages/Ürün/ProductPurchase";
import ProductPurchaseList from "../Pages/Ürün/ProductPurchaseList";

export default function Body({ addImageButton }) {


  return <>
    <Switch>

      <Route exact path="/dashboard/shipment-list">
        <SevkiyatList />
      </Route>
      <Route exact path="/dashboard/shipment-note/:shipID">
        <ShipmentNote />
      </Route>
      <Route exact path="/dashboard/load-list/:shipID">
        <LoadList />
      </Route>
      <Route exact path="/dashboard/load-list/self/:shipID">
        <LoadList />
      </Route>
      <Route exact path="/dashboard/load-status/:loadID/:loadJobID">
        <LoadStatus />
      </Route>
      <Route exact path="/dashboard/load-image/:type/:loadID/:loadJobID">
        <UploadImage addImageButton={addImageButton} />
      </Route>
      <Route exact path="/dashboard/load-external/:shipID">
        <ExternalLoad />
      </Route>
      <Route exact path="/dashboard/diesel-input/:shipID">
        <DieselInput />
      </Route>
      <Route exact path="/dashboard/raport">
        <Raport />
      </Route>
      <Route exact path="/dashboard/product-list">
        <ProductList />
      </Route>
      <Route exact path="/dashboard/product-purchase">
        <ProductPurchase />
      </Route>
      <Route exact path="/dashboard/product-purchase-list">
        <ProductPurchaseList />
      </Route>

    </Switch>
  </>
}