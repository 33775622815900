
import EkmekkAlert from "../Views/alert"
import React, { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import Profile from "../DataCenter/profile"
// import { faCoffee, faMoneyBillWave, faHotel, faShoppingBasket, faLifeRing, faSyringe, faTh, faTachometerAlt, faCashRegister, faUsers, faBuilding, faCubes, faBone, faDoorOpen, faCalendarAlt, faUserCog, faPiggyBank, faUniversity } from '@fortawesome/free-solid-svg-icons'
export default function Ekmekk() {


    function GetHeaders() {

        var headDriverDizi = [
            {
                itemName: "Sevkiyat Listesi",
                router: "/dashboard/shipment-list",
                isPhotoUpload: false,
                first: true,
                // auth: [0, 1, 94],
                // subPage: [],
                // icon: faTachometerAlt,
            }, {
                itemName: "Yük Listesi",
                router: "/dashboard/load-list",
                isPhotoUpload: false,
                first: false,
                // auth: [0, 1, 94],
                // subPage: [],
                // icon: faCalendarAlt,
            },
            {
                itemName: "Yük Durum Seçimi",
                router: "/dashboard/load-status",
                isPhotoUpload: false,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faShoppingBasket
            },
            {
                itemName: "İçin Resim Yükle",
                router: "/dashboard/load-image",
                isPhotoUpload: true,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faHotel
            },
            {
                itemName: "Dış Yük Girişi",
                router: "/dashboard/load-external",
                isPhotoUpload: false,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faHotel
            },
            {
                itemName: "Sevkiyat Notu",
                router: "/dashboard/shipment-note",
                isPhotoUpload: false,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faHotel
            },
            {
                itemName: "Mazot Ver",
                router: "/dashboard/diesel-input",
                isPhotoUpload: false,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faHotel
            },
            {
                itemName: "Rapor",
                router: "/dashboard/raport",
                isPhotoUpload: false,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faHotel
            },
            {
                itemName: "Ürün Listesi",
                router: "/dashboard/product-list",
                isPhotoUpload: false,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faHotel
            },
            {
                itemName: "Ürün Alımı",
                router: "/dashboard/product-purchase",
                isPhotoUpload: false,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faHotel
            },
            {
                itemName: "Ürün Alış - 0050",
                router: "/dashboard/product-purchase-list",
                isPhotoUpload: false,
                first: false,
                // auth: [27],
                // delete: 1,
                // subPage: [],
                // icon: faHotel
            },
            {
                itemName: "Çıkış",
                router: "/login",
                // icon: faDoorOpen,
                first: false,
                // subPage: [],
            },
        ]

        return headDriverDizi;

    }

    function GetImageURL(url) {
        return "https://31.210.91.239:3602/images/" + url
    }
    return {
        Alert: EkmekkAlert(),
        Profile: Profile(),
        URL: "https://31.210.91.239:3602",
        GetHeaders,
        // GetPermissionList,
        GetImageURL,

    }
}

Ekmekk.PERMISSION = { BAR: "1" };