import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import axios from '../../axios'
import { useForm } from 'react-hook-form'


const drawerWidth = 240;
var qs = require('qs');


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        marginTop: 6,

    },
    pos: {
        marginBottom: 12,
    },
    content: {
        flexGrow: 1,
        // height: '100vh',
        overflow: 'auto',
        padding: theme.spacing(1, 2, 1),

    },
    heroContent: {
        padding: theme.spacing(4, 2, 1),
    },
    inputContent: {
        marginBottom: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },

    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    py2: {
        paddingTop: theme.spacing(1),
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
}));

export default function ShipmentNote() {

    const classes = useStyles();
    const theme = useTheme();
    const { shipID } = useParams();

    
    const {register, handleSubmit, errors} = useForm()

    const shipmentNoteHandler = (data) => {
        data.shippingId = shipID
        data = qs.stringify(data)
        axios.post('notekle_api.php',data)
        .then(r => {
            console.log(r.data);
        })
        .catch(err => {
            console.log(err);
        })

        alert(JSON.stringify(data))
    }



    return <>
        <form className={classes.form} noValidate onSubmit={handleSubmit((shipmentNoteHandler))}>
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
            >
                <TextField
                    id="outlined-secondary"
                    label="Not giriniz"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    helperText="Eklemek istediğiniz notu üstteki alana girebilirsiniz."
                    className={classes.inputContent}
                    name="soforNot"
                    inputRef={register({
                        required: "Bu Alan boş bıraklamaz!"
                    })}
                    error={Boolean(errors.query)}
                    helperText={errors.query?.message}
                />

                <Button className={classes.inputContent} type="submit" fullWidth variant="contained" color="primary">KAYDET</Button>
                <Button className={classes.inputContent} fullWidth variant="outlined" color="primary">Vazgeç</Button>
            </Grid>


        </Container>
        </form>


    {/* <Container maxWidth="lg" className={classes.container}>
             <Grid container spacing={3}>
                 <Grid item xs={12} md={8} lg={9}>
                     <Paper className={fixedHeightPaper}>
                         <Chart />
                    </Paper>
                 </Grid>
                 <Grid item xs={12} md={4} lg={3}>
                     <Paper className={fixedHeightPaper}>
                       <Deposits />
                     </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                         <Orders />
                    </Paper>
                 </Grid>
             </Grid>

         </Container> */}
    </>
}